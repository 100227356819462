import { initializeApp } from 'firebase/app'
import {getAuth} from 'firebase/auth'
import {getDatabase} from 'firebase/database'
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDWmqk_OexP2RoVdqQfx4TBwSWjylT0A5c",
  authDomain: "piximo-b10db.firebaseapp.com",
  databaseURL: "https://piximo-b10db-default-rtdb.firebaseio.com",
  projectId: "piximo-b10db",
  storageBucket: "piximo-b10db.appspot.com",
  messagingSenderId: "828528608248",
  appId: "1:828528608248:web:a6ca570788cfbf8a3e4c0d",
  measurementId: "G-G9WR8VVP2B"
};

// Initialize Firebase and Firebase Authentication
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getDatabase(app)
const storage = getStorage(app)

export {auth, db, storage}

