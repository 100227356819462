import { useState, useEffect } from 'react'
import { Outlet, Link } from "react-router-dom";
import { db } from '../firebase/firebase'
import { push, ref } from 'firebase/database'
import { Loading } from './loading'
import Modal from 'react-modal'
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "none",
    background: "transparent",
  },
}




export function Header({ fixed }) {
  const [email, setEmail] = useState()
  const [mEmail, mSetEmail] = useState()
  const [sModalIsOpen, sSetIsOpen] = useState()
  const [cModalIsOpen, cSetIsOpen] = useState()
  const [loading, setLoading] = useState(false)
  const [mount, setMount] = useState(false)

  const addMailing = (email) => {
    setLoading(true)
    cSetIsOpen(true)
    push(ref(db, `mailing/`), {
      email
    }).then(() => {
      setLoading(false)
      setEmail("")
  })
  }

  useEffect(() => {
    if (!mount) {
      setTimeout(() => {sSetIsOpen(true)}, 1000)
      setMount(true)
    }
  }
  )

  return (
    <>
      <div id="header" class="flex flex-col lg:flex-row items-center justify-between pb-5">
        <div class="lg:basis-2/3 mx-10 mt-10 lg:mt-0 lg:mx-20 space-y-5">
          <div class="flex flex-col lg:flex-row flex-start lg:space-x-2">
            <h1 class="text-7xl font-bold">Hi, I'm</h1>
            <h1 class="text-7xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-primary to-primary">
              Pixi.
            </h1>
          </div>
          <ul class="list-disc ml-5 space-y-0">

            <li class="text-wrap text-xl">I'm a <b>mobile minimart</b> that vends within crowds.</li>
            <li class="text-wrap text-xl"> I'm driven by remote drivers like <b>you</b>.</li>
            <li class="text-wrap text-xl">
              Coming soon: a <b>driving competition</b> for you to win prizes!
            </li>
          </ul>
          <div class="flex flex-col lg:flex-row items-center space-x-0 lg:space-x-2 space-y-2 lg:space-y-0">
          <input type="email" name="email" id="email" value={email} onChange={e => setEmail(e.target.value)} class="text-center bg-gray-50 border w-full lg:basis-1/3 border-gray-300 text-gray-900 sm:text-sm rounded-xl focus:ring-primary-600 focus:border-primary-600 block p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your email" required="" />
          <button class="rounded-xl bg-primary text-white px-10 py-2 w-full lg:basis-1/3 hover:opacity-75" onClick={() => {addMailing(email)}}>Get updates</button>
            {/* <Link
                    className="flex text-md font-bold leading-snug text-black hover:opacity-75 font-light"
                    to="/signup"
                  ><button class="rounded-xl bg-primary text-white px-5 py-1 m-auto">Sign up</button> */}
            {/* </Link> */}
            {/* <a
                    className="flex text-md font-bold leading-snug text-black hover:opacity-75 font-light"
                    href="#demo"
                  >See a demo
                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-1">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
</svg>



            </a> */}
          </div>

        </div>
        <div class="lg:basis-1/3 lg:col-span-4 mx-10 lg:mx-20 justify-start">
       
          <img src={require("../img/blink.gif")} class="rounded-2xl my-5" alt="Blinking Piximo."></img>
        </div>
      </div>
       <Modal
        isOpen={cModalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => {cSetIsOpen(false)}}
        style={customStyles}
        contentLabel="Confirm Modal"
      >
        {/* {!loading ? ( */}
        <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            {!loading ? (
              <>
              <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary bg-opacity-30 sm:mx-0 sm:h-10 sm:w-10">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-primary w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Email submitted.</h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">Thanks for signing up! We'll send you updates soon.</p>
                </div>
              </div>
              </>
            ) : (
              <>
                <Loading />
              </>
            )}
          </div>
        </div>
        {!loading ? (
        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <button type="button" onClick={() => {cSetIsOpen(false)}} class="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary hover:bg-opacity-75 sm:ml-3 sm:w-auto">Close</button>
        </div>
        ): (<></>)}
      </div>
      </Modal>
      <Modal
        isOpen={sModalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => {sSetIsOpen(false)}}
        style={customStyles}
        contentLabel="Onload modal"
      >
        {/* {!loading ? ( */}
        <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex flex-col justify-center items-center space-y-5">
            {!loading ? (
              <>
              <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-primary bg-opacity-30">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-7 text-primary">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 3.75H6.912a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859M12 3v8.25m0 0l-3-3m3 3l3-3" />
</svg>

              </div>
              <div class="mt-5 text-center sm:mt-0 sm:ml-4 sm:text-center">
                <h3 class="text-base font-semibold leading-6 text-gray-900 font-bold text-3xl" id="modal-title">Get updates on Piximo.</h3>
                <div class="mt-5">
                  <p class="text-md text-gray-900">Piximo is a robotics startup that specializes in mobile minimarts like Pixi. Interested in what we offer?</p>
                  <p class="text-md mt-3 mb-2 font-bold text-gray-900">Sign up for our mailing list.</p>
                  <input type="email" name="email" id="email" value={mEmail} onChange={e => mSetEmail(e.target.value)} class="text-center bg-gray-50 border w-full lg:basis-1/3 border-gray-300 text-gray-900 sm:text-sm rounded-xl focus:ring-primary-600 focus:border-primary-600 block p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your email" required="" />
                </div>
              </div>
              </>
            ) : (
              <>
                <Loading />
              </>
            )}
          </div>
        </div>
        {!loading ? (
        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <button type="button" onClick={() => {   sSetIsOpen(false)
          addMailing(mEmail) 
         }} class="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary hover:bg-opacity-75 sm:ml-3 sm:w-auto">Submit</button>
          <button type="button" onClick={() => {sSetIsOpen(false)}} class="inline-flex w-full justify-center rounded-md  px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm hover:bg-gray-100 hover:bg-opacity-75 sm:ml-3 sm:w-auto">Close</button>
        </div>
        ): (<></>)}
      </div>
      </Modal>
    </>
  );
}