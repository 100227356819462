export function PreLaunch() {

  return (
    <>
      <div class="flex w-full mt-20 flex-grow flex-col justify-center items-center text-center space-y-2">
        <h1 class="text-5xl font-extrabold text-primary">
          Thanks for registering.
        </h1>
        <p class="text-xl text-black">Check back for our soft launch in mid-April.</p>
      </div>
    </>
  )
}
